import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/util/seo"
import ColumnContent from "../components/content/columnContent"
import "../assets/scss/layout/pages/contact.scss"
import ContactForm from "../components/form/contactForm";

const ContactPage = ({data, path}) => {
  const content = data.wpPage

  return (
    <Layout mt={true} path={path}>
      <SEO title="Contact" />
      <div className={'contact justify-content-lg-between'}>
        <ColumnContent title={content.title} contentFR={content.content} contentEN={content.ACF.content} />
        <ContactForm />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    wpPage(slug: {eq: "contact"}) {
      title
      content
      ACF {
        content
      }
    }
  }
`

export default ContactPage

import React from "react"

const ContactForm = () => (
  <form
    name={'contact'}
    method={'POST'}
    data-netlify={'true'}
    data-netlify-honeypot={'bot-field'}
    className={'contact__form form col-5'}
  >
    <input type={'hidden'} name={'form-name'} value={'contact'} />
    <input name={"name"} type={'text'} placeholder={'Name'} />
    <input name={"email"} type={'email'} placeholder={'E-mail'} />
    <input name={"object"} type={'text'} placeholder={'Object'} />
    <textarea name={'message'} placeholder={'Your message'} />
    <button type={'submit'}>Send</button>
  </form>
)

export default ContactForm
